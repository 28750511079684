import { useEffect } from 'react'
import { whenPlayerReady } from '../utils'

function useInsertAdsHtml(player, adSlotId, videoSlotId, playerId) {
    useEffect(() => {
        whenPlayerReady(player, () => {
            const playerWrapper = document.querySelector(`div#${playerId}`)
            playerWrapper.insertAdjacentHTML(
                'beforeend',
                `<div class="container">
                    <div class="ad-container">
                        <div id="${adSlotId}" class="ad-slot">
                            <video id="${videoSlotId}" class="ad-player"></video>
                        </div>
                    </div>
                </div>`
            )
        })
    }, [player, adSlotId, playerId, videoSlotId])
}

export default useInsertAdsHtml
