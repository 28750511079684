import { useEffect, useState } from 'react'
import videojs from 'video.js'
import playlistPlugin from 'videojs-playlist'
import getCommonPlayerSettings from '../common-player-settings'
import { getCurrentItem, sendContentStopEvent, isEpgPlayerInBackground } from '../utils'

const initializePlayer = (ctitle = '', playerId, showPlayBtn = false) => {
    if (videojs.getPlugin('playlist') === 'undefined') {
        videojs.registerPlugin('playlist', playlistPlugin)
    }

    const player = videojs(playerId, {
        fill: true, // fill available container space
        ...getCommonPlayerSettings(ctitle, showPlayBtn),
        // override native HLS support provided by some platforms as this can be buggy
        html5: {
            hls: {
                overrideNative: true,
            },
            nativeAudioTracks: false,
            nativeVideoTracks: false,
        },
    })

    return player
}

function usePlayerInitialize(firstItemTitle = '', playerId, showPlayBtn) {
    const [player, setPlayer] = useState({})

    useEffect(() => {
        if (isEpgPlayerInBackground()) {
            // EPG player is hidden, so don't initialize
            return
        }

        const p = initializePlayer(firstItemTitle, playerId, showPlayBtn)
        setPlayer(p)

        return () => {
            const currentItem = getCurrentItem(p)

            if (currentItem) {
                sendContentStopEvent(p, currentItem)
            } else {
                console.warn('Not sending content stop event: no current item')
            }
            console.log('disposing of player')
            p.dispose()
        }
    }, [firstItemTitle, playerId, showPlayBtn])

    return player
}

export default usePlayerInitialize
