import { useEffect } from 'react'
import { sendGaEvents, whenPlayerReady } from '../utils'

function useSendGaEvents(player, contentViewThreshold, seriesCode, channel) {
    useEffect(() => {
        let removeListeners

        whenPlayerReady(player, () => {
            removeListeners = sendGaEvents(player, contentViewThreshold, seriesCode)
        })

        return () => {
            if (typeof removeListeners === 'function') {
                removeListeners()
            }
        }
        // reset hook when channel changes
    }, [channel, player, contentViewThreshold, seriesCode])
}

export default useSendGaEvents
