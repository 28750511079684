import styled from '@emotion/styled'

const PlayerWrapper = styled.div`
    height: 100%;

    &#epg-player-wrapper {
        position: absolute;
        height: 100%;
        width: 100%;

        video:focus {
            outline: none;
        }

        #epg-player {
            margin: auto;
        }

        .hidden {
            visibility: hidden;
        }

        .hide-controls > .vjs-control-bar {
            visibility: hidden;
        }

        .vjs-custom-skin {
            .vjs-right-control-group {
                margin-left: 0;
            }

            .vjs-play-progress:before {
                display: none;
            }

            video[muted='muted'] ~ div.vjs-control-bar {
                .vjs-mute-control .vjs-icon-placeholder:before {
                    content: url('/button-sound-mute.svg');
                }
            }
        }
    }

    .container {
        position: relative;

        .ad-slot iframe {
            /* pointer-events: none; */
            z-index: 0 !important;
            width: 100% !important;
            height: 100vh !important;
        }

        .ad-container {
            z-index: -1;
        }

        .ad-container.make-visible {
            visibility: visible;
            z-index: 1;
        }

        .ad-container,
        .ad-slot video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100vh !important;
        }
    }

    .poster {
        max-height: 50vh;
    }

    .hidden {
        visibility: hidden;
    }

    .vjs-custom-skin {
        @media screen and (max-width: 672px) {
            overflow: hidden;
        }

        > video {
            margin: auto;

            &:focus {
                outline: none;
            }
        }

        &:not(.vjs-has-started) .vjs-big-play-button {
            display: flex;
            top: calc(50% - 60px);
            left: calc(50% - 60px);
            margin-top: 0;
            margin-left: 0;
            height: 120px;
            width: 120px;
            background-color: rgba(255, 255, 255, 0.4);
            border-radius: 50%;
            border: none;
            justify-content: center;
            align-items: center;
        }

        .vjs-error-display.vjs-modal-dialog {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: rgb(20, 20, 20);
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
            border-radius: 4px;
            overflow: auto;
            z-index: 6;
            pointer-events: all;
            position: absolute;
            height: 308px;
            width: 498px;
            top: calc(50% - (308px / 2));
            left: calc(50% - (498px / 2));

            color: rgb(255, 255, 255);
            font-size: 16px;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: normal;
            letter-spacing: 0px;
            line-height: 20px;

            &:before {
                content: none;
            }

            .vjs-modal-dialog-content {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .vjs-close-button {
                opacity: 0.6;
            }

            @media screen and (max-width: 672px) {
                height: 356px;
                width: 90vw;
                top: calc(50% - (356px / 2));
                left: 5vw;
            }
        }

        .vjs-big-play-button .vjs-icon-placeholder {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            margin: auto;
            transform: scale(1.5);

            &:before {
                position: unset;
            }
        }

        .vjs-background-gradient {
            position: absolute;
            bottom: 0;
            height: 260px;
            width: calc(100% + 96px);
            background: linear-gradient(-180deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.95) 100%);
            border-radius: 0px;
            margin: 0 -48px -30px;
            overflow: hidden;
            z-index: 1 !important;

            @media screen and (max-width: 672px) {
                height: 165px;
                width: calc(100% + 32px);
                margin: 0 -16px -18px;
            }
        }

        .vjs-now-playing {
            p {
                color: rgb(255, 255, 255);
                font-size: 12px;
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 1.04px;
                line-height: 16px;
                opacity: 0.7;

                @media screen and (max-width: 672px) {
                    font-size: 11px;
                    letter-spacing: 0.96px;
                    line-height: 16px;
                }
            }

            h1 {
                max-width: 623px;
                color: rgb(255, 255, 255);
                font-size: 36px;
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 600;
                letter-spacing: 0.2px;
                line-height: 44px;

                @media screen and (max-width: 672px) {
                    font-size: 17px;
                    letter-spacing: 0.1px;
                    line-height: 20px;
                }

                &.ad-counter {
                    display: none;
                }
            }
        }

        .vjs-progress-holder.vjs-slider {
            border-radius: 0;
            height: 4px;
            background-color: rgba(255, 255, 255, 0.3);
        }

        .vjs-progress-holder .vjs-play-progress {
            background: rgb(85, 157, 212);
        }

        .vjs-play-progress:before {
            position: absolute;
            top: 0;
            right: -0.5rem;
            transform: translateY(-40%);
            font-size: 16px;
            z-index: 1;
            color: rgb(85, 157, 212);

            @media screen and (max-width: 672px) {
                display: none;
            }
        }

        .vjs-play-progress:after {
            border-radius: 0;
            font-size: 0.875rem;
            padding: 0;
            width: 3.5rem;
            height: 1.5em;
            line-height: 1.5em;
            top: -3em;
        }

        .vjs-progress-control:hover .vjs-time-tooltip,
        .vjs-progress-control:hover .vjs-progress-holder:focus .vjs-time-tooltip {
            font-size: 0.875rem;
        }

        .vjs-progress-control:hover .vjs-play-progress:before {
            /* adjust position of white circle indicating current progress */
            /* font-size: 20px; */
        }

        .vjs-progress-holder .vjs-load-progress,
        .vjs-progress-holder .vjs-load-progress div {
            background: rgba(85, 157, 212, 0.2);
        }

        .vjs-back-control {
            display: none;
        }

        &.vjs-has-started > .vjs-back-control {
            display: flex !important;
            align-items: center;
            visibility: visible;
            opacity: 1;
            transition: visibility 0.1s, opacity 0.1s;
            top: 7px;
            left: 46px;
            position: absolute;
            height: 60px;
            transform: scale(1.2);
            z-index: 3;

            &:focus {
                outline: none;
            }

            &:hover {
                cursor: pointer;
                opacity: 1;
                transition: opacity 1s ease-in-out;
            }

            > img {
                width: 30px;
            }

            @media screen and (max-width: 672px) {
                left: 14px;
                top: 2px;

                > img {
                    width: 24px;
                }
            }
        }

        &.vjs-has-started {
            &.vjs-user-inactive {
                &.vjs-playing {
                    .vjs-back-control {
                        opacity: 0;
                        transition: visibility 1s, opacity 1s;
                    }
                }
            }
        }

        .vjs-control-bar {
            flex-flow: row wrap;
            background: none;
            font-size: 1.25rem;
            color: #ffffff;
            margin: 0 48px 30px;
            height: unset;
            width: unset;

            > div {
                z-index: 2;
            }

            @media screen and (max-width: 672px) {
                margin: 0 16px 5px;
            }

            &.ad-started {
                .vjs-right-control-group,
                .vjs-progress-control {
                    display: none;
                }

                .vjs-now-playing {
                    h1 {
                        display: none;
                    }

                    h1.ad-counter {
                        display: block;
                    }
                }
            }
        }
        .vjs-right-control-group {
            position: relative;
            display: flex;
            height: 55px;
            width: 100%;
            flex-flow: row wrap;
            justify-content: flex-end;

            /* adjust positioning of buttons to vertically align with progress bar */
            flex-basis: calc(100% + 35px);
            margin-left: 0;
            margin-right: -30px;

            .vjs-play-control-btns {
                margin: 0 auto;
                width: 200px;
                display: flex;
                position: absolute;
                justify-content: center;
                left: calc(50% - 100px);
                align-items: center;
            }

            @media screen and (max-width: 672px) {
                margin-top: -12px;

                .vjs-volume-panel.vjs-volume-panel-vertical {
                    margin-left: -16px;
                    margin-right: 3px;
                }

                .vjs-play-control-btns {
                    width: 120px;
                    left: calc(50% - 60px);
                }

                .vjs-playlist-previous,
                .vjs-playlist-next {
                    width: 2em;
                }
            }
        }

        .vjs-right-control-group .vjs-control:not(.vjs-volume-control):not(.vjs-volume-panel) {
            height: 20px;
            opacity: 0.7;
            transition: opacity 0.3s ease-in-out;

            &:focus {
                outline: none !important;
            }

            &:hover {
                opacity: 1;
                transition: opacity 0.3s ease-in-out;
            }
        }

        &.video-js .vjs-progress-control.vjs-control {
            height: 19px;
            margin-top: 24px;
            width: 100%;

            @media screen and (max-width: 672px) {
                margin-top: 10px;
            }
        }

        .vjs-time-remaining-wrapper {
            display: flex;
            flex-grow: 1;
            height: 19px;
            margin-top: 20px;
        }

        .vjs-current-time {
            display: flex;
            align-items: center;
        }

        .vjs-time-control {
            padding: 0;
            font-size: 14px;
            z-index: 5;
            opacity: 0.7;
            text-align: center;
        }

        .vjs-current-time-display {
            font-family: 'Open Sans';
            font-size: 14px;
            font-weight: normal;
            opacity: 0.7;
            color: white;
        }

        .vjs-time-divider {
            display: flex;
            padding: 0 3px;
            justify-content: center;
            align-items: center;
            font-family: 'Open Sans';
            font-size: 14px;
            font-weight: normal;
            opacity: 0.7;
            color: white;
            min-width: unset;
        }

        .video-js .vjs-duration,
        .vjs-no-flex .vjs-duration {
            display: initial;
        }

        .vjs-time-remaining-wrapper > .vjs-duration {
            display: flex;
            align-items: center;
        }

        .vjs-duration-display {
            font-family: 'Open Sans';
            font-size: 14px;
            font-weight: normal;
            color: white;
            opacity: 0.7;

            @media screen and (max-width: 672px) {
                font-size: 12px;
                line-height: 16px;
            }
        }

        .vjs-time-tooltip {
            display: none;
        }

        .vjs-play-control {
            &:focus {
                outline: none;
            }
            width: 36px;
        }
    }

    .video-js .vjs-progress-control .vjs-progress-holder {
        margin: 0;
    }

    .vjs-volume-vertical {
        background-color: rgba(60, 60, 60, 0.7);
        z-index: 5;
    }

    .vjs-volume-panel .vjs-slider {
        background-color: rgba(85, 157, 212, 0.5);
    }

    .video-js:hover .vjs-big-play-button,
    .video-js .vjs-big-play-button:focus {
    }

    /* Custom icons */
    .vjs-icon-play:before,
    .video-js .vjs-play-control .vjs-icon-placeholder:before,
    .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
        content: url('/button-play.svg');
        display: flex;
        margin: 0;
        line-height: 1.5;
        justify-content: center;
    }

    .vjs-icon-pause:before,
    .video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
        content: url('/button-pause.svg');
        line-height: 1.5;
    }

    .vjs-icon-volume-high:before,
    .video-js .vjs-mute-control .vjs-icon-placeholder:before {
        content: url('/button-sound.svg');
        line-height: 1.5;
    }

    .vjs-icon-volume-mute:before,
    .video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before {
        content: url('/button-sound-mute.svg');
    }

    .vjs-icon-fullscreen-enter:before,
    .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
        display: flex;
        justify-content: flex-end;
    }

    .vjs-right-control-group .vjs-play-control-btns {
        .vjs-playlist-previous .vjs-icon-placeholder:before {
            content: url('/button-previous.svg');
            display: flex;
            margin: 0;
            line-height: 1.85;
            justify-content: center;
            cursor: pointer;
        }
        .vjs-playlist-next .vjs-icon-placeholder:before {
            content: url('/button-next.svg');
            display: flex;
            margin: 0;
            line-height: 1.85;
            justify-content: center;
            cursor: pointer;
        }
    }
`

export default PlayerWrapper
