import { useEffect } from 'react'
import { whenPlayerReady } from '../utils'

function useTrackVideoPlays(player, contentViewThreshold) {
    useEffect(() => {
        let onPlaylistItemChange, timer

        whenPlayerReady(player, () => {
            const onPlaying = () => {
                // fallback to 3 seconds
                const hasPlayedTime = contentViewThreshold ? contentViewThreshold * 1000 : 3000

                timer = setTimeout(() => {
                    if (!player.paused()) {
                        if (contentViewThreshold) {
                            window.VideoElephant.videosPlayedSinceLastAd += 1
                            console.warn(
                                'incremented videos played counter',
                                window.VideoElephant.videosPlayedSinceLastAd
                            )
                        } else {
                            console.warn('content view threshold not set - not incrementing videos played')
                        }

                        if (!window.VideoElephant.hasFirstVideoPlayedYet) {
                            window.VideoElephant.hasFirstVideoPlayedYet = true
                            console.warn('set has video played yet:', window.VideoElephant.hasFirstVideoPlayedYet)
                        }
                    }
                    player.off('playing', onPlaying)
                }, hasPlayedTime)
            }

            onPlaylistItemChange = () => {
                clearTimeout(timer)
                player.off('playing', onPlaying)
                player.one('playing', onPlaying)
            }
            player.on('playlistitem', onPlaylistItemChange)
        })

        return () => {
            clearTimeout(timer)
            if (typeof onPlaylistItemChange === 'function') {
                player.off('playlistitem', onPlaylistItemChange)
            }
        }
    }, [player, contentViewThreshold])
}

export default useTrackVideoPlays
