import videojs from 'video.js'
import {
    Back,
    NowPlaying,
    Settings,
    RightControlGroup,
    Gradient,
    TimeWrapper,
    PlayControlBtns,
    PlaylistNextBtn,
    PlaylistPreviousBtn,
} from './player-controls'

videojs.registerComponent('Back', Back)
videojs.registerComponent('NowPlaying', NowPlaying)
videojs.registerComponent('Settings', Settings)
videojs.registerComponent('RightControlGroup', RightControlGroup)
videojs.registerComponent('TimeWrapper', TimeWrapper)
videojs.registerComponent('Gradient', Gradient)
videojs.registerComponent('PlayControlBtns', PlayControlBtns)
videojs.registerComponent('PlaylistNextBtn', PlaylistNextBtn)
videojs.registerComponent('PlaylistPreviousBtn', PlaylistPreviousBtn)

const getCommonSettings = (ctitle = '', showPlayBtn = true) => ({
    inactivityTimeout: 5000,
    errorDisplay: {
        uncloseable: false,
    },
    Back: {},
    controlBar: {
        children: {
            NowPlaying: {
                ctitle: ctitle,
            },
            ProgressControl: {
                children: {
                    SeekBar: {
                        children: {
                            LoadProgressBar: {},
                            PlayProgressBar: {},
                        },
                    },
                },
            },
            RightControlGroup: {
                children: {
                    TimeWrapper: {
                        children: {
                            CurrentTimeDisplay: {},
                            TimeDivider: {},
                            DurationDisplay: {},
                        },
                    },
                    ...(showPlayBtn && {
                        PlayControlBtns: {
                            children: {
                                PlaylistPreviousBtn: {},
                                PlayToggle: {},
                                PlaylistNextBtn: {},
                            },
                        },
                    }),
                    FullscreenToggle: {},
                    VolumePanel: {
                        inline: false,
                    },
                },
            },
            Gradient: {},
        },
    },
})

export default getCommonSettings
