import { useEffect } from 'react'
import { get, whenPlayerReady } from '../utils'

const setMuteToggleListener = (player, setPlayerMuteState, shouldMute) => {
    const mt = get(['controlBar', 'RightControlGroup', 'VolumePanel', 'muteToggle'], player)

    if (mt) {
        mt.one(['click', 'tap'], ev => {
            setPlayerMuteState(!shouldMute)
        })
    } else {
        console.warn('Mute toggle button is undefined')
    }
}

function setPlayerMute(player, shouldMute) {
    if (typeof player.muted === 'function') {
        player.muted(shouldMute)

        player[shouldMute ? 'addClass' : 'removeClass']('muted')
    }
}

function setAdsPlayerMute(shouldMute) {
    if (typeof window !== 'undefined' && window.directAdOS) {
        const sdk = window.directAdOS

        sdk.setAdVolume(shouldMute ? 0 : 1)
    }
}

function usePlayerMute(player, shouldMute, setPlayerMuteState) {
    useEffect(() => {
        whenPlayerReady(player, () => {
            setMuteToggleListener(player, setPlayerMuteState, shouldMute)
            setPlayerMute(player, shouldMute)
            setAdsPlayerMute(shouldMute)
        })
    }, [player, shouldMute, setPlayerMuteState])
}

export default usePlayerMute
