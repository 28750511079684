import { useEffect } from 'react'
import { getCurrentItem, sendContentStopEvent, whenPlayerReady } from '../utils'

const setPlayerSource = (channel, player) => {
    if (typeof channel.getType === 'undefined') {
        return
    }

    const type = channel.getType()

    if (type === 'channel-stream') {
        setStreamChannel(channel, player)
    } else if (type === 'channel-collection') {
        setSimulatedLinearChannel(channel, player)
    }
}

const setStreamChannel = (channel, player) => {
    const src = channel.getSource()

    if (src && player) {
        player.playlist([])
        player.src({
            src,
        })
    } else {
        player.reset()
    }
}

const setSimulatedLinearChannel = (channel, player) => {
    const playlist = channel.getVideoJsPlaylist()
    const { time, index } = channel.getPlaylistItemAndTimeToPlay()

    if (playlist.length === 0) {
        player.reset()
    }
    // load playlist and begin playing video at index
    player.playlist(playlist, index)
    // continue to next playlist item immediately after playback of previous ends
    player.playlist.autoadvance(0)
    // play items on a loop
    player.playlist.repeat(true)
    // set current video time
    player.currentTime(time)
}

function useSetPlayerSource(player, channel) {
    useEffect(() => {
        whenPlayerReady(player, () => {
            if (!player.paused()) {
                // trigger GA content stop event when channel changes
                sendContentStopEvent(player, getCurrentItem(player))
            }
            setPlayerSource(channel, player)
        })
    }, [channel, player])
}

export default useSetPlayerSource
