import styled from '@emotion/styled'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import StyledPlayerWrapper from '../player-styling'
import {
    useHandleAutoplay,
    usePlayerInitialize,
    usePlayerMute,
    useSendGaEvents,
    useRequestSpotxAd,
    useTrackVideoPlays,
    useInsertAdsHtml,
} from '../hooks'
import { disableVideoContextMenu, get, setNowPlayingTitle, whenPlayerReady } from '../utils'
import '../video-js.css'
import actionTypes from '../state/actionTypes'
import useSetPlayerSource from '../hooks/useSetPlayerSource'

const getCurrentlyPlayingEventTitle = (channel = {}, player) => {
    const type = channel.getType ? channel.getType() : ''
    let title

    if (type === 'channel-stream') {
        const currEvent = channel.getCurrentlyPlayingEvent ? channel.getCurrentlyPlayingEvent() : {}
        title = currEvent.getTitle ? currEvent.getTitle() : ''
    } else if (type === 'channel-collection') {
        const playlist = player.playlist()
        const currIndex = player.playlist.currentItem()
        if (currIndex >= 0) {
            const currPlaylistItem = playlist[currIndex]
            title = currPlaylistItem.title
        }
    }

    return title
}

const setFullscreenListener = (player, shouldHideControls) => {
    if (typeof player.on === 'function') {
        player.on('fullscreenchange', _ => {
            hideEpgControls(player, shouldHideControls)
        })
    }
}

const hideEpgControls = (player, shouldHideControls) => {
    if (shouldHideControls) {
        if (player.isFullscreen()) {
            player.removeClass('hide-controls')
        } else {
            player.addClass('hide-controls')
        }
    }
}
const setOnUserActiveListener = (player, channel) => {
    if (typeof player.on === 'function') {
        // TODO: use 'sourceset' event once it's deemed to be no longer experimental
        player.on('useractive', ev => {
            const title = getCurrentlyPlayingEventTitle(channel, player)

            setNowPlayingTitle(player, title)
        })
    }
}

const setOnChannelPlayListener = (player, channel) => {
    if (typeof player.one === 'function' && typeof channel === 'object' && Object.keys(channel).length > 0) {
        const cName = typeof channel.getName === 'function' ? channel.getName() : null
        const cId = typeof channel.getChannelID === 'function' ? channel.getChannelID() : null

        player.one('play', ev => {
            trackCustomEvent({
                category: 'Channel Start',
                action: `Content`,
                label: cName || cId || '(not set)',
            })
        })
    }
}

const disablePauseOnClick = () => {
    document.querySelector('#epg-player-wrapper #epg-player video').style.pointerEvents = 'none'
}

const disableVideoSeek = () => {
    document.querySelector('#epg-player-wrapper .vjs-progress-control').style.pointerEvents = 'none'
}

function useHandleFullscreenChange(player, shouldHideControls) {
    useEffect(() => {
        whenPlayerReady(player, () => {
            setFullscreenListener(player, shouldHideControls)
            hideEpgControls(player, shouldHideControls)
        })
    }, [player, shouldHideControls])
}

function useDisableEpgPlayerComponents(player) {
    useEffect(() => {
        whenPlayerReady(player, () => {
            disableVideoContextMenu('epg-player-wrapper')
            disablePauseOnClick()
            disableVideoSeek()
        })
    }, [player])
}

function useHandleNowPlayingTitle(channel, player) {
    useEffect(() => {
        whenPlayerReady(player, () => {
            const title = getCurrentlyPlayingEventTitle(channel, player)
            setNowPlayingTitle(player, title)

            setOnUserActiveListener(player, channel)
        })
    }, [channel, player])
}

function EpgPlayer({
    channel,
    contentViewThreshold,
    shouldAutoplay = true,
    shouldHideControls = false,
    shouldMute,
    setEpgPlayerMute,
}) {
    const adSlotId = 'epg-ad-slot'
    const videoSlotId = 'epg-video-slot'
    const playerId = 'epg-player'

    const player = usePlayerInitialize(undefined, playerId)

    useInsertAdsHtml(player, adSlotId, videoSlotId, playerId)
    useHandleNowPlayingTitle(channel, player)
    useHandleFullscreenChange(player, shouldHideControls)
    useRequestSpotxAd(player, channel, shouldAutoplay, shouldMute, adSlotId, videoSlotId)
    useDisableEpgPlayerComponents(player)
    useSetPlayerSource(player, channel)
    usePlayerMute(player, shouldMute, setEpgPlayerMute)
    useSendGaEvents(player, contentViewThreshold, null, channel)
    useEffect(() => {
        whenPlayerReady(player, () => {
            setOnChannelPlayListener(player, channel)
        })
    }, [channel, player])
    useTrackVideoPlays(player, contentViewThreshold)
    useHandleAutoplay(player, shouldAutoplay, channel)

    return (
        <StyledPlayerWrapper id="epg-player-wrapper">
            {/* eslint-disable-next-line */}
            <video
                id={playerId}
                className="vjs-custom-skin video-js hidden vjs-big-play-centered"
                controls
                preload="auto"
            ></video>
            {channel.offAir ? (
                <OffAir>
                    <h1>Channel Off Air</h1>
                </OffAir>
            ) : null}
        </StyledPlayerWrapper>
    )
}

const mapStateToProps = state => {
    return {
        contentViewThreshold: get(['config', 'contentViewThreshold'], state),
        shouldMute: typeof state.epgPlayerMute !== 'undefined' ? state.epgPlayerMute : true,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setEpgPlayerMute: shouldMute => dispatch({ type: actionTypes.SET_EPG_PLAYER_MUTE, mute: shouldMute }),
    }
}

const ConnectedEpgPlayer = connect(mapStateToProps, mapDispatchToProps)(EpgPlayer)

const OffAir = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: black;

    @media screen and (max-width: 672px) {
        height: 50%;
        z-index: 2;
    }
`

export default ConnectedEpgPlayer
