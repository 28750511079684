import { navigate } from 'gatsby'
import videojs from 'video.js'
import { sendContentStopEvent, getCurrentItem } from './utils'

const VjsButton = videojs.getComponent('Button')
const VjsComponent = videojs.getComponent('Component')
const VjsControlBar = videojs.getComponent('Component')

class Back extends VjsButton {
    createEl() {
        return super.createEl('div', {
            className: 'vjs-back-control',
            id: 'player-back-btn',
            innerHTML: '<img src="/button-shelf-left.svg" alt="Go back" />',
        })
    }

    handleClick() {
        const path = window.location.pathname

        if (path === '/epg-player') {
            window.history.back()
        } else {
            const slug = path.slice(7)

            navigate(`/watch${slug}`)
        }
    }
}

class NowPlaying extends VjsComponent {
    constructor(player, options) {
        super(player, options)

        this.on('nowplayingitemchange', (_, { data: title }) => {
            const el = this.el()

            if (el) {
                el.innerHTML = this.getInnerHtml(title)
            }
        })
    }

    createEl() {
        return super.createEl('div', {
            className: 'vjs-now-playing',
            innerHTML: this.getInnerHtml(this.options_.ctitle),
        })
    }

    getInnerHtml(title) {
        if (title && title !== 'No Programme Data') {
            return '<p>Now Playing</p><h1>' + title + '</h1><h1 class="ad-counter">Advertisement</h1>'
        }

        return ''
    }
}

class Settings extends VjsButton {
    createEl() {
        return super.createEl('div', {
            className: 'vjs-control vjs-button vjs-settings-control',
        })
    }

    handleClick() {
        this.player().trigger('settings')
    }
}

class RightControlGroup extends VjsControlBar {
    createEl() {
        return super.createEl('div', {
            className: 'vjs-right-control-group',
            dir: 'ltr',
        })
    }
}

class PlayControlBtns extends VjsControlBar {
    createEl() {
        return super.createEl('div', {
            className: 'vjs-play-control-btns',
        })
    }
}

class PlaylistNextBtn extends VjsButton {
    createEl() {
        return super.createEl('div', {
            className: 'vjs-playlist-next vjs-control vjs-button',
        })
    }

    handleClick() {
        const p = this.player()

        // trigger GA content stop event when playlist item changes
        sendContentStopEvent(p, getCurrentItem(p))

        p.playlist.next()
    }
}

class PlaylistPreviousBtn extends VjsButton {
    createEl() {
        return super.createEl('div', {
            className: 'vjs-playlist-previous vjs-control vjs-button',
        })
    }

    handleClick() {
        const p = this.player()
        // trigger GA content stop event when playlist item changes
        sendContentStopEvent(p, getCurrentItem(p))

        const prevItem = p.playlist.previous()

        if (!prevItem) {
            // no previous item means already at start of playlist, so restart video instead
            p.currentTime(0)
        }
    }
}

class TimeWrapper extends VjsComponent {
    createEl() {
        return super.createEl('div', {
            className: 'vjs-time-remaining-wrapper',
        })
    }
}

class Gradient extends VjsComponent {
    createEl() {
        return super.createEl('div', {
            className: 'vjs-background-gradient',
        })
    }
}

export {
    Back,
    NowPlaying,
    Settings,
    RightControlGroup,
    Gradient,
    TimeWrapper,
    PlayControlBtns,
    PlaylistNextBtn,
    PlaylistPreviousBtn,
}
