import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { get, loadSpotXSdk, setPlaylistItemChangeListener, tryToAutoplayVideo, whenPlayerReady } from '../utils'

const isAdPlaying = () => (typeof window !== 'undefined' && window.directAdOS ? window.directAdOS.loaded() : false)

const toggleTvGuideInteraction = shouldAllow => {
    const g = document.querySelector('.tv-guide')

    if (g) {
        g.classList.toggle('disable-interaction', !shouldAllow)
    }
}

const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)

const setPlaylistTime = (channel, player) => {
    if (typeof channel.getPlaylistItemAndTimeToPlay === 'function') {
        const { time, index } = channel.getPlaylistItemAndTimeToPlay()
        const p = player.playlist()
        player.playlist(p, index)
        player.currentTime(time)
    } else {
        console.error('Unable to get playlist item & time for channel:', channel)
    }
}

const updateVideoJsControlBarForAd = force => {
    const controlBar = document.querySelector('.vjs-control-bar')

    controlBar.classList.toggle('ad-started', force)
}

const updateNowPlayingInfoForAd = force => {
    const nowPlayingInfo = document.querySelector('#now-playing-info')

    // now playing info not shown on mobile
    if (nowPlayingInfo) {
        nowPlayingInfo.classList.toggle('ad-started', force)
    }
}

const onAdLoad = (directAdOS, adContainer, adsPlayer, player, shouldMuteRef, shouldAutoplay) => {
    player.muted(true)
    player.pause()

    directAdOS.setAdVolume(shouldMuteRef.current ? 0 : 1)

    tryToAutoplayVideo(adsPlayer, shouldAutoplay)
    toggleTvGuideInteraction(false)
    updateVideoJsControlBarForAd(true)
    updateNowPlayingInfoForAd(true)
    adContainer.classList.add('make-visible')
}

const onAdEnd = (directAdOS, adContainer, adsPlayer, player, shouldMuteRef, channelRef) => {
    updateVideoJsControlBarForAd(false)
    updateNowPlayingInfoForAd(false)
    toggleTvGuideInteraction(true)
    adContainer.classList.remove('make-visible')
    directAdOS.setAdVolume(0)
    adsPlayer.src = ''

    player.muted(shouldMuteRef.current)
    tryToAutoplayVideo(player, true)
    window.VideoElephant.videosPlayedSinceLastAd = 0

    if (channelRef.current && channelRef.current.getType() === 'channel-collection') {
        setPlaylistTime(channelRef.current, player)
    }
}

const requestSpotXAd = (player, directAdOS, shouldMuteRef, shouldAutoplay, channelRef) => {
    const adContainer = document.querySelector('.ad-container')
    const adsPlayer = document.querySelector('.ad-slot > video')

    if (isVideoPlaying(adsPlayer)) {
        console.warn('ad already playing - not requesting a new one')
        return
    }

    const onEnd = () => onAdEnd(directAdOS, adContainer, adsPlayer, player, shouldMuteRef, channelRef)
    const onLoad = () => onAdLoad(directAdOS, adContainer, adsPlayer, player, shouldMuteRef, shouldAutoplay)

    directAdOS.subscribe(onEnd, 'AdStopped')
    directAdOS.subscribe(onEnd, 'AdError')
    directAdOS.subscribe(onEnd, 'AdSkipped')
    directAdOS.subscribe(onLoad, 'AdLoaded')
    directAdOS.loadAd().then(onLoad)

    return () => {
        if (isAdPlaying()) {
            directAdOS.destroy()
            window.VideoElephant.videosPlayedSinceLastAd = 0
        }
        directAdOS.unsubscribe(onEnd, 'AdStopped')
        directAdOS.unsubscribe(onEnd, 'AdError')
        directAdOS.unsubscribe(onEnd, 'AdSkipped')
        directAdOS.unsubscribe(onLoad, 'AdLoaded')
    }
}

function shouldRequestAd(adFrequency, adType, channelId) {
    const videosPlayedSinceLastAd = typeof window !== 'undefined' ? window.VideoElephant.videosPlayedSinceLastAd : 0
    const hasFirstVideoPlayedYet = typeof window !== 'undefined' ? window.VideoElephant.hasFirstVideoPlayedYet : false

    if (adType === 'undefined' || adFrequency === 'undefined' || channelId === 'undefined') {
        // Ediflo config hasn't loaded, so don't display ads
        return false
    }

    if (adFrequency === 0) {
        return false
    }

    if (adType === 'postroll' && videosPlayedSinceLastAd === 0) {
        return false
    }

    if (adType === 'preroll' && hasFirstVideoPlayedYet === false) {
        return true
    }

    if (adFrequency > videosPlayedSinceLastAd) {
        return false
    }

    if (['preroll', 'postroll'].includes(adType) && adFrequency <= videosPlayedSinceLastAd) {
        return true
    }

    return false
}

function useRequestSpotxAd(player, channel, shouldAutoplay, shouldMute, adSlotId, videoSlotId) {
    const adFrequency = useSelector(state => get(['config', 'adRules', 'frequency'], state))
    const adType = useSelector(state => get(['config', 'adRules', 'type'], state))
    const adChannelId = useSelector(state => get(['config', 'adRules', 'web', 'channel_id'], state))
    const publisherId = useSelector(state => get(['config', 'adRules', 'publisher_id'], state))
    const shouldMuteRef = useRef(true)
    const channelRef = useRef({})
    shouldMuteRef.current = shouldMute
    channelRef.current = channel

    useEffect(() => {
        let onPlaylistItemChange, unsubscribeAdOs

        if (isAdPlaying()) {
            console.warn('ad already playing - returning')
            return
        }

        whenPlayerReady(player, () => {
            onPlaylistItemChange = setPlaylistItemChangeListener(player, () => {
                console.warn('inside on playlist change')
                if (!shouldRequestAd(adFrequency, adType, adChannelId)) {
                    console.warn('not requesting ad')
                    tryToAutoplayVideo(player, shouldAutoplay)
                    return
                }

                // re-initializing SDK is recommended approach found in SpotX docs (see JWPlayer playlist example)
                loadSpotXSdk(player, publisherId, adChannelId, adSlotId, videoSlotId)
                    .then(directAdOs => {
                        console.warn('requesting ad')
                        unsubscribeAdOs = requestSpotXAd(player, directAdOs, shouldMuteRef, shouldAutoplay, channelRef)
                    })
                    .catch(err => {
                        console.error('error loading SpotX SDK:', err)
                        tryToAutoplayVideo(player, shouldAutoplay)
                    })
            })
        })

        return () => {
            if (typeof player.off === 'function') {
                player.off('playlistitem', onPlaylistItemChange)
            }
            if (typeof unsubscribeAdOs === 'function') {
                unsubscribeAdOs()
            }
        }
    }, [
        adFrequency,
        adSlotId,
        adType,
        adChannelId,
        channelRef,
        player,
        publisherId,
        shouldAutoplay,
        shouldMuteRef,
        videoSlotId,
    ])
}

export default useRequestSpotxAd
