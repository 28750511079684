import { useEffect } from 'react'
import { tryToAutoplayVideo, whenPlayerReady } from '../utils'

function useHandleAutoplay(player, shouldAutoplay, channel = null) {
    useEffect(() => {
        whenPlayerReady(player, () => {
            player.removeClass('hidden')
            tryToAutoplayVideo(player, shouldAutoplay)
        })
    }, [channel, player, shouldAutoplay])
}

export default useHandleAutoplay
