import { useEffect } from 'react'
import { whenPlayerReady, getCurrentItem } from '../utils'

function updateUrl(player) {
    const { slug, title } = getCurrentItem(player)

    const pageTitle = `${title} | VideoElephant`
    document.title = pageTitle

    if (typeof window !== 'undefined' && !window.location.pathname.includes(slug)) {
        window.history.pushState({}, pageTitle, `/player/${slug}`)
    }
}

function useUpdateUrlOnPlaylistItemChange(player, shouldUpdateUrl) {
    useEffect(() => {
        if (!shouldUpdateUrl) {
            return
        }

        let onItemChange

        whenPlayerReady(player, () => {
            onItemChange = () => updateUrl(player)

            player.on('playlistitem', onItemChange)
        })

        return () => {
            if (onItemChange) {
                player.off('playlistitem', onItemChange)
            }
        }
    }, [player, shouldUpdateUrl])
}

export default useUpdateUrlOnPlaylistItemChange
